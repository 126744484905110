import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  CarOutlined,
  LinkOutlined, ProfileOutlined,
  ShoppingOutlined,
  SolutionOutlined
} from '@ant-design/icons'
import { Col, Row } from 'antd'

import { Button } from 'src/components/Button'
import { WidgetFrame } from 'src/components/WidgetFrame'
import { useAuthen } from 'src/hooks/useAuthen'
import { useTheme } from 'src/hooks/useTheme'
import { EnumRoute } from 'src/router/types'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { EnumTheme } from 'src/utils/types'

import './styles.scss'

export default function Home() {
  const navigate = useNavigate()
  const { isAnonymous, currentUser } = useAuthen()
  const [isDealer, setIsDealer] = useState<boolean | null>(false)
  const { theme } = useTheme()

  // Check if its dealer or not:

  const handleClickSignUp = () => {
    if (navigate) {
      navigate(EnumRoute.SignUp)
    }
  }

  const handleClickStart = () => {
    if (navigate) {
      navigate(EnumRoute.NewTransaction)
    }
  }

  const handleClickAccount = () => {
    if (navigate) {
      navigate(EnumRoute.Account)
    }
  }

  const handleGetStartedClick = () => {
    if (navigate) {
      navigate(EnumRoute.NewTransaction)
    }
  }

  const handleNavigate = (route: string) => {
    navigate(route)
  }

  const widgets = [
    {
      title: 'Start Transactions',
      description: 'Generate sale documents',
      icon: <ShoppingOutlined />,
      route: EnumRoute.NewTransaction
    },
    {
      title: 'Shipping',
      description: 'Quote, track, and book shipping',
      icon: <CarOutlined />,
      route: EnumRoute.ShippingAndTracking
    },
    {
      title: 'Description Builder',
      description: 'Build a detailed car description',
      icon: <ProfileOutlined />,
      route: EnumRoute.DescriptionBuilder
    },
    {
      title: 'Verify ID',
      description: 'Verify ID documents',
      icon: <SolutionOutlined />,
      route: EnumRoute.VerifyIdentity
    }
  ]

  return (
    <div className="home-wrapper">
      <div className="widget-container">
        {widgets.map(({
          title, description, icon, route
        }) => (
          <WidgetFrame
            key={title}
            title={title}
            description={description}
            icon={icon}
            onClick={() => handleNavigate(route)}
          />
        ))}
        <WidgetFrame
          title="Live Auction Browser"
          description="Browser consumer auctions"
          icon={<LinkOutlined />}
          onClick={() => window.open('https://auction.pacer.co/', '_blank')}
        />
      </div>
      <Row>
        <Col span={18} offset={3}>

          {isAnonymous && (
            <Button
              className="second-btn"
              color="secondary"
              size="large"
              block
              onClick={handleClickSignUp}
            >
              Sign Up
            </Button>
          )}
        </Col>
      </Row>

      {/* </Col> */}
    </div>
  )
}
