import React, { useContext, useEffect, useState } from 'react'

import { Button } from 'src/components/Button'
import { HeaderLayout } from 'src/components/HeaderLayout'
import { getFirebaseAuth } from 'src/firebase/firebase'
import { useAuthen } from 'src/hooks/useAuthen'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { store } from 'src/store/store'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { EnumUserRole, EnumUserType } from 'src/utils/types'

import { CarData } from './types'

export default function Warranty() {
  const { openMessage } = useMessage()
  const globalStore = useContext(store)
  const { currentUser } = useAuthen()
  const {
    mainUser,
    secondaryUser
  } = globalStore.state.createTransaction
  const encyptPack: string = '{ "Email":"test123@gmail.com", "PhoneNumber":"9111222333", "ZipCode":"19904", "VINNumber":"2T2HZMDA9MC292659", "Mileage":"null", "PartnerID":"3" }'
  const carInfo: CarData = {
    Email: currentUser?.email ? currentUser?.email : '',
    PhoneNumber: currentUser?.phoneNumber || '5555555555',
    ZipCode: EnumUserType.Main ? mainUser.zip! : secondaryUser.zip!,
    VINNumber: globalStore.state.createTransaction.vehicle.vin,
    Mileage: globalStore.state.createTransaction.vehicle.odometerReading
      ? globalStore.state.createTransaction.vehicle.odometerReading : null,
    PartnerID: '3'
  }

  const fairTestData = 'LS0tLS1CRUdJTiBQR1AgTUVTU0FHRS0tLS0tCgp3Y0JNQSt0cjVkdVFmbE1jQVFmL2RiM1hGanVJalVaQm5qYnVrTTZRTmIyK2l4UnBzVkZpM3Nud01DWmsKNXJhTytxbzFQVmZZNzdrOERkMlU3QW8vVDNSV1JvdmRGQW9TU0Q2R1pKSVZ3WEhTZ1VHZ3FBNWVZdkZ0CnFBRVJ5TWcwRjdUUHFnTWRMcDFoUHlvRXdwRi96QnZJSHBnWjlRRll3U0tIMzdBQ2hyam5lUFYrdnA0QgpHRHU4NVdqL09EYVkzTm5pZlE3VE9MVHVBcWthYlFnWVREenNXTVFXSm42a3NiQlJnTGpDRStla2FTY2IKY05mOWNQMmd5L1lFanA5bTdxbkt1TkRkdmI1MWpPL3FFZ2YvQzFib0VMOXliN0lHQlQ4YTEzUnZPY2FsCjVWYUxZeDd3Vm83K09qM1pCTjBiamdvQVh4WnRMV05UdXFkanVRU1h6STNmZHhETTNzNmhXbzdUNmN6QwpwOUovQVhEWHFmVFZjNUpUQ2pvYmt0WnRFakJSMzFlNWVoYStndUI2ejJSSHRzUElaU3RXbUtpTjgxWFUKWGkvUDJMVk9IWHZId3EvczQ2TXRFNStKbFpiV091MThjTTZ6WkkvQ1JKZDVUT0Z6Ui9Ia1BINHJCRVdCCjJHblVnNXlPWG5rbjB1OU0vbDR4QUZnUVQ2MlVpV0ZYeU5GSU1NaGRmMlBlWEI2UzdGb1Y5Zz09Cj00U0JKCi0tLS0tRU5EIFBHUCBNRVNTQUdFLS0tLS0K'

  const [finalUrl, setFinalUrl] = useState('')
  const [fairData, setFairData] = useState(null)
  const [isFetching, setIsFetching] = useState(true)
  useEffect(() => {
    let pollingInterval: string | number | NodeJS.Timeout | undefined
    const handleEncrypt = async () => {
      const info = JSON.stringify(carInfo)
      // const info = encyptPack;
      console.log(info)
      if (info) {
        try {
          const encryption = (await (await authApiAxiosWithRefresh()
          ).post('/encryption/encrypt', {
            info
          }))
          const encryptedMessage = encryption.data.token
          const newUrl = `https://app.fairwarranty.com/vehicle?token=${encodeURIComponent(encryptedMessage)}`
          console.log(newUrl)
          setFinalUrl(newUrl)
        } catch (err) {
          openMessage({
            text1: 'Failed to crypt',
            type: EnumMessageType.ERROR
          })
        }
      }
    }

    handleEncrypt()
    // testFair();
    // handleDecrypt(fairTestData);
    if (isFetching) {
      // Poll the backend every 2 seconds
      pollingInterval = setInterval(() => {
        pingFair()
      }, 2000)
      return () => clearInterval(pollingInterval)
    }
    clearInterval(pollingInterval)
    return () => { }
  }, [])

  const testFair = async () => {
    try {
      const encryption = (await (await authApiAxiosWithRefresh()
      ).post('/encryption/fair', {
        message: fairTestData
      }))
      console.log(encryption)
    } catch (err) {
      console.log(err)
    }
  }
  const handleDecrypt = async (message: string) => {
    if (message) {
      // console.log(message)
      try {
        const decryption = (await (await authApiAxiosWithRefresh()
        ).post('/encryption/decrypt', {
          message
        }))
        console.log(JSON.parse(decryption.data.decryptedMessage))
        setFairData(JSON.parse(decryption.data.decryptedMessage))
      } catch (err) {
        openMessage({
          text1: 'Failed to decrypt',
          type: EnumMessageType.ERROR
        })
      }
    }
  }
  const pingFair = async () => {
    //   console.log("pinging")
    //   console.log(currentUser?.uid)
    try {
      const ping = (await (await authApiAxiosWithRefresh()
      ).post('/encryption/fairPing', {
        message: currentUser?.uid
      }))
      if (ping.data.message) {
        return
      }
      console.log(JSON.parse(ping.data.message))
      setFairData(JSON.parse(ping.data.message))
      setIsFetching(false)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <HeaderLayout title="Warranty" hasGoBack style={{ width: '100%', height: '100%' }}>
      {finalUrl ? (
        fairData ? (
          <div>

            <p>
              Registered Fair Email:
              {(fairData as { email: string }).email}
            </p>

            <p>
              Fair User Id:
              {(fairData as { fairId: string }).fairId}
            </p>
            <p>
              Fair Warranty Id:
              {(fairData as { fairWarrantyId: string }).fairWarrantyId}
            </p>
          </div>
        ) : (
          <div style={{ width: '100%', height: '100%' }}>
            <iframe src={finalUrl} style={{ width: '100%', height: '100%' }} allow="clipboard-write" title="fairFrame" />
          </div>
        )
      ) : (
        <div>
          <p>Encrypting message and generating URL...</p>
        </div>
      )}

    </HeaderLayout>
  )
}
