import { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { EnumNewWarrantyStep, EnumRoute } from 'src/router/types'
import { store } from 'src/store/store'

import { useQuery } from './useQuery'

export type NewWarrantyParams = {
    step: EnumNewWarrantyStep
    transactionId?: string | null
}

interface WarrantyStepArg {
    step: EnumNewWarrantyStep
    transactionId?: string | null
}

export function useNewWarranty() {
  const globalStore = useContext(store)
  const query = useQuery()
  const navigate = useNavigate()
  const step = useMemo(() => query.get('step') as EnumNewWarrantyStep ?? EnumNewWarrantyStep.MainInfo, [query])
  const transactionId = useMemo(() => query.get('transactionId'), [query])
  const navigateToWarStep = (arg: WarrantyStepArg) => {
    let queryString = `?step=${arg.step}`
    const lastParams: NewWarrantyParams = {
      step: arg.step
    }

    if (arg.transactionId || (arg.transactionId === undefined && transactionId)) {
      queryString = `${queryString}&transactionId=${arg.transactionId ?? transactionId}`
      lastParams.transactionId = arg.transactionId ?? transactionId
    }

    globalStore.dispatch({
      type: 'UPDATE_LAST_WARRANTY_PARAMS',
      lastParams
    })
    navigate(`${EnumRoute.Warranty}${queryString}`)
  }
  const resetNewWarState = () => {
    globalStore.dispatch({
      type: 'RESET_CREATE_WARRANTY_STATE'
    })
  }
  return {
    step,
    transactionId,
    navigateToWarStep,
    resetNewWarState
  }
}
