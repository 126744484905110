import React, { useEffect, useRef, useState } from 'react'
import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'

import { Feature } from 'src/types/descriptionBuilder'

import './styles.scss'

interface FeatureCardProps {
  text: string;
  initialStatus: 'pending' | 'added';
  onStatusChange: (newStatus: 'pending' | 'added') => void;
  onEdit: (newText: string) => void;
}

export const FeatureCard: React.FC<FeatureCardProps> = ({
  text, initialStatus, onStatusChange, onEdit
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(text)
  const [status, setStatus] = useState<'added' | 'pending'>(initialStatus)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value)
    if (!isEditing) {
      onEdit(event.target.value)
    }
  }

  const handleButtonClick = () => {
    setIsEditing(false)
    if (status === 'pending') {
      setStatus('added')
      onStatusChange('added')
    } else {
      setStatus('pending')
      onStatusChange('pending')
    }
  }

  const handleEditButtonClick = () => {
    setIsEditing(true)
    setTimeout(() => {
      if (textAreaRef.current) {
        textAreaRef.current.focus()
        const { length } = textAreaRef.current.value
        textAreaRef.current.setSelectionRange(length, length)
      }
    }, 100) // Slight delay to ensure focus event triggers properly
    if (status === 'added') {
      setStatus('pending')
      onStatusChange('pending')
    }
  }

  return (
    <div className={`feature-card ${status === 'added' ? 'green' : ''}`}>
      <textarea
        ref={textAreaRef}
        className={`feature-card__text ${!value ? 'empty' : ''}`}
        value={value}
        onChange={handleInputChange}
        onBlur={() => isEditing && onEdit(value)}
        readOnly={!isEditing}
        placeholder="Add Another Feature"
      />
      <button type="button" className="feature-card__button" onClick={handleEditButtonClick}>
        <EditOutlined />
      </button>
      {value !== '' && (
        <button type="button" className="feature-card__button" onClick={handleButtonClick}>
          {status === 'added' ? <MinusOutlined /> : <PlusOutlined />}
        </button>
      )}
    </div>
  )
}

/// feature cards for DescriptionBuilder page
interface FeatureCardsArrayProps {
  initialFeatures: { [category: string]: Feature[] };
  handleStatusChange: (newStatus: 'added' | 'pending', category: string, feature: string | {key: string, value: any}) => void;
  handleTextChange: (newText: string, category: string, prevText: string) => void;
}
const CategoryOrder = [
  'Limited Production Features',
  'Trim Packages',
  'Engine and Transmission Features',
  'Brake and Suspension Features',
  'Exterior Features',
  'Interior Features',
  'Additional Features',
  'Exterior Color'
]

export const FeatureCardsArray: React.FC<FeatureCardsArrayProps> = ({
  initialFeatures,
  handleStatusChange,
  handleTextChange
}) => {
  const sortedCategories = Object.keys(initialFeatures).sort((a, b) => {
    const indexA = CategoryOrder.indexOf(a)
    const indexB = CategoryOrder.indexOf(b)
    return indexA - indexB
  })
  return (
    <div>
      {initialFeatures && sortedCategories.map((category) => (
        <Col span={24} key={category}>
          <h2>{category}</h2>
          <Row gutter={16}>
            {Array.isArray(initialFeatures[category]) && initialFeatures[category].map((feature, i) => (
              <Col key={`${category}-${feature.text}-${feature.initialStatus}-${i}`} xs={12} sm={12} md={8} lg={8}>
                <FeatureCard
                  text={feature.text}
                  initialStatus={feature.initialStatus}
                  onStatusChange={(newStatus) => {
                    handleStatusChange(newStatus, category, feature.text)
                  }}
                  onEdit={(newText) => {
                    handleTextChange(newText, category, feature.text)
                  }}
                />
              </Col>
            ))}
            <Col key={`${category}-blank-${Math.random()}`} xs={12} sm={12} md={8} lg={8}>
              <FeatureCard
                text={''}
                initialStatus={'pending'}
                onStatusChange={(newStatus) => {
                  handleStatusChange(newStatus, category, '')
                }}
                onEdit={(newText) => {
                  handleTextChange(newText, category, '')
                }}
              />
            </Col>
          </Row>
        </Col>
      ))}
    </div>
  )
}
