import { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { EnumNewTransactionParams, EnumRoute } from 'src/router/types'
import { TransactionParams } from 'src/store/reducers/createTransactionReducer'
import { store } from 'src/store/store'
import { KycStatus } from 'src/types/users'
import { ALLOW_TRANSACTION_SHARING } from 'src/utils/constants'
import { kycStatusOfSeller, transactionUserInfo } from 'src/utils/storeHelpers'
import { EnumShareStep, EnumUserRole, EnumUserType } from 'src/utils/types'

import { useQuery } from './useQuery'

interface TransactionStepArg {
  step: EnumNewTransactionParams
  userType?: EnumUserType
  hasBeenShared?: boolean | null
  shareStep?: EnumShareStep
  shareTransactionId?: string | null
  shareUrl?: string
}
export function useNewTransaction() {
  const globalStore = useContext(store)
  const query = useQuery()
  const navigate = useNavigate()
  const step = useMemo(() => query.get('step'), [query])
  const userType = useMemo(() => query.get('userType') as EnumUserType ?? EnumUserType.Main, [query])
  const shareTransactionId = useMemo(() => query.get('shareTransactionId'), [query])
  const shareStep = useMemo(() => query.get('shareStep') as (EnumShareStep | null), [query])
  const hasBeenShared = useMemo(() => query.get('hasBeenShared') === 'true', [query])
  const shareUrl = useMemo(() => {
    const shareUrlParam = query.get('shareUrl')
    return shareUrlParam ? decodeURIComponent(shareUrlParam) : ''
  }, [query])
  const { role: userRole } = transactionUserInfo(userType, globalStore)
  const {
    documentEnabled, paymentTransfer, mainUser, secondaryUser
  } = globalStore.state.createTransaction
  const { enabled: paymentTransferEnabled, protectionEnabled } = paymentTransfer

  const isSeller = useMemo(() => userRole === EnumUserRole.Seller, [userRole])

  const isBuyer = useMemo(() => userRole === EnumUserRole.Buyer, [userRole])

  const isSecondary = useMemo(() => userType === EnumUserType.Secondary, [userType])

  const seller = useMemo(() => (mainUser.role === EnumUserRole.Seller ? mainUser : secondaryUser), [mainUser, secondaryUser])
  const buyer = useMemo(() => (mainUser.role === EnumUserRole.Buyer ? mainUser : secondaryUser), [mainUser, secondaryUser])

  const isFirstSharedPage = useMemo(() => {
    return ((
      (step === EnumNewTransactionParams.NeededInfo && userType === EnumUserType.Secondary)
      || (
        step === EnumNewTransactionParams.KnownIssuesAgreement
        && shareStep === EnumShareStep.NeedsPayment
        && userRole === EnumUserRole.Buyer
        && protectionEnabled
      )
      || (
        step === EnumNewTransactionParams.FeeInfo
        && shareStep === EnumShareStep.NeedsPayment
        && (
          userRole === EnumUserRole.Seller
          || (userRole === EnumUserRole.Buyer && !protectionEnabled)
        )
      )
      || (
        step === EnumNewTransactionParams.KnownIssuesDisclosure
        && shareStep === EnumShareStep.UpdateKnownIssues
        && userRole === EnumUserRole.Seller
        && protectionEnabled
      )
      || (
        step === EnumNewTransactionParams.Complete
        && shareStep === EnumShareStep.NeedsPayment
        && !protectionEnabled
        && !documentEnabled
      )
    ) && hasBeenShared)
  }, [step, userType, hasBeenShared, userRole, shareStep, globalStore.state.createTransaction])

  const navigateToStep = (arg: TransactionStepArg) => {
    let queryString = `?step=${arg.step}&userType=${arg.userType ?? userType}`
    const lastParams: TransactionParams = {
      step: arg.step,
      userType: arg.userType ?? userType
    }

    if (arg.shareTransactionId || (arg.shareTransactionId === undefined && shareTransactionId)) {
      queryString = `${queryString}&shareTransactionId=${arg.shareTransactionId ?? shareTransactionId}`
      lastParams.shareTransactionId = arg.shareTransactionId ?? shareTransactionId
    }

    if (arg.hasBeenShared || (arg.hasBeenShared === undefined && hasBeenShared)) {
      queryString = `${queryString}&hasBeenShared=${arg.hasBeenShared ?? hasBeenShared}`
      lastParams.hasBeenShared = arg.hasBeenShared ?? hasBeenShared
    }

    if (arg.shareStep || (arg.shareStep === undefined && shareStep)) {
      queryString = `${queryString}&shareStep=${arg.shareStep ?? shareStep}`
      lastParams.shareStep = arg.shareStep ?? shareStep
    }

    if (arg.shareUrl) {
      queryString = `${queryString}&shareUrl=${arg.shareUrl}`
    }

    globalStore.dispatch({
      type: 'UPDATE_LAST_TRANSACTION_PARAMS',
      lastParams
    })
    navigate(`${EnumRoute.NewTransaction}${queryString}`)
  }

  const goPrevStep = async () => {
    let kycStatus = KycStatus.NOT_STARTED
    switch (step) {
    // @TODO: REMOVE THIS CASE ONCE VT IS FIGURED OUT!
    // case EnumNewTransactionParams.UserRoles:
    //   navigateToStep({ step: EnumNewTransactionParams.TempStop })
    //   break

    case EnumNewTransactionParams.ServicesSelection:
      navigateToStep({ step: EnumNewTransactionParams.UserRoles })
      break

    case EnumNewTransactionParams.UserInformation:
      if (userType === EnumUserType.Main) {
        navigateToStep({ step: EnumNewTransactionParams.ServicesSelection })
      } else {
        navigateToStep({ step: EnumNewTransactionParams.NeededInfo })
      }
      break

    case EnumNewTransactionParams.MainVehicleInfo:
      kycStatus = await kycStatusOfSeller(globalStore)
      if (userRole === EnumUserRole.Seller && paymentTransferEnabled && kycStatus !== KycStatus.COMPLETED) {
        navigateToStep({ step: EnumNewTransactionParams.Kyc })
      } else {
        navigateToStep({ step: EnumNewTransactionParams.UserInformation })
      }
      break

    case EnumNewTransactionParams.NeededInfo:
      if (userType === EnumUserType.Main) {
        navigateToStep({ step: EnumNewTransactionParams.MainVehicleInfo })
      } else if (userType === EnumUserType.Secondary) {
        if (hasBeenShared && shareTransactionId) {
          globalStore.dispatch({
            type: 'RESET_TRANSACTION_STATE'
          })
          navigate(EnumRoute.NewTransaction)
        } else {
          navigateToStep({
            step: ALLOW_TRANSACTION_SHARING ? EnumNewTransactionParams.NextUser : EnumNewTransactionParams.Signature,
            userType: EnumUserType.Main
          })
        }
      }
      break

    case EnumNewTransactionParams.SpecificVehicleInfo:
      kycStatus = await kycStatusOfSeller(globalStore)
      if (userType === EnumUserType.Main) {
        if (userRole === EnumUserRole.Buyer) {
          if (!documentEnabled) {
            navigateToStep({ step: EnumNewTransactionParams.UserInformation })
          } else {
            navigateToStep({ step: EnumNewTransactionParams.NeededInfo })
          }
        } else {
          if (!documentEnabled) {
            if (paymentTransferEnabled && kycStatus !== KycStatus.COMPLETED) {
              navigateToStep({ step: EnumNewTransactionParams.Kyc })
            } else {
              navigateToStep({ step: EnumNewTransactionParams.UserInformation })
            }
          } else {
            navigateToStep({ step: EnumNewTransactionParams.MainVehicleInfo })
          }
        }
      } else {
        if (userRole === EnumUserRole.Seller && paymentTransferEnabled && kycStatus !== KycStatus.COMPLETED) {
          navigateToStep({ step: EnumNewTransactionParams.Kyc })
        } else if (paymentTransferEnabled && protectionEnabled) {
          navigateToStep({ step: EnumNewTransactionParams.PaymentProtectionDisclosure })
        } else {
          navigateToStep({ step: EnumNewTransactionParams.UserInformation })
        }
      }
      break

    case EnumNewTransactionParams.PaymentProtectionDisclosure:
      navigateToStep({ step: EnumNewTransactionParams.UserInformation })
      break

    case EnumNewTransactionParams.KnownIssuesDisclosure:
      if (!documentEnabled && userType === EnumUserType.Secondary) {
        navigateToStep({ step: EnumNewTransactionParams.Kyc })
      } else {
        navigateToStep({ step: EnumNewTransactionParams.SpecificVehicleInfo })
      }
      break

    case EnumNewTransactionParams.KnownIssuesAgreement:
      navigateToStep({ step: EnumNewTransactionParams.PaymentProtectionDisclosure })
      break

    case EnumNewTransactionParams.SignatureApplication:
      if (userType === EnumUserType.Secondary && userRole === EnumUserRole.Buyer) {
        if (paymentTransferEnabled && protectionEnabled) {
          navigateToStep({ step: EnumNewTransactionParams.KnownIssuesAgreement })
        } else {
          navigateToStep({ step: EnumNewTransactionParams.UserInformation })
        }
      } else {
        navigateToStep({ step: EnumNewTransactionParams.SpecificVehicleInfo })
      }
      break

    case EnumNewTransactionParams.SignatureOdometer:
      if (userRole === EnumUserRole.Seller) {
        if (paymentTransferEnabled && protectionEnabled) {
          navigateToStep({ step: EnumNewTransactionParams.KnownIssuesDisclosure })
        } else {
          navigateToStep({ step: EnumNewTransactionParams.SpecificVehicleInfo })
        }
      } else {
        navigateToStep({ step: EnumNewTransactionParams.SignatureApplication })
      }
      break

    case EnumNewTransactionParams.NextUser:
      if (!documentEnabled) {
        if (isSeller) {
          navigateToStep({ step: EnumNewTransactionParams.KnownIssuesDisclosure })
        } else {
          navigateToStep({ step: EnumNewTransactionParams.SpecificVehicleInfo })
        }
      } else {
        if (globalStore.state.createTransaction.isInternationalAddress && userRole === EnumUserRole.Seller) {
          navigateToStep({ step: EnumNewTransactionParams.SignatureOdometer })
        } else {
          navigateToStep({ step: EnumNewTransactionParams.Signature })
        }
      }
      break

    case EnumNewTransactionParams.ShareConfirmation:
      globalStore.dispatch({
        type: 'RESET_TRANSACTION_STATE'
      })
      navigate(EnumRoute.NewTransaction)
      break

    case EnumNewTransactionParams.Signature:
      // if (userRole === EnumUserRole.SelfTransfer) {
      //   navigateToStep({ step: EnumNewTransactionParams.SignatureApplication })
      // } else {
      //   navigateToStep({ step: EnumNewTransactionParams.SignatureOdometer })
      // }
      navigateToStep({ step: EnumNewTransactionParams.SignatureOdometer })
      break

      // case EnumNewTransactionParams.ShippingInfo:
      //   if (globalStore.state.createTransaction.isInternationalAddress && userRole === EnumUserRole.Seller) {
      //     navigateToStep({ step: EnumNewTransactionParams.SignatureOdometer })
      //   } else {
      //     navigateToStep({ step: EnumNewTransactionParams.Signature })
      //   }
      //   break

    case EnumNewTransactionParams.FeeInfo:
      if (userType === EnumUserType.Main && shareStep === EnumShareStep.NeedsPayment && userRole === EnumUserRole.Buyer) {
        navigateToStep({ step: EnumNewTransactionParams.KnownIssuesAgreement })
      } else {
        navigateToStep({ step: EnumNewTransactionParams.Signature })
      }
      break

    case EnumNewTransactionParams.Complete:
      if (!documentEnabled) {
        if (hasBeenShared && isBuyer && protectionEnabled) {
          navigateToStep({ step: EnumNewTransactionParams.KnownIssuesAgreement })
        } else if ((protectionEnabled && isSeller) || (hasBeenShared && shareStep === EnumShareStep.UpdateKnownIssues)) {
          navigateToStep({ step: EnumNewTransactionParams.KnownIssuesDisclosure })
        } else if (isSecondary) {
          if (isSeller) {
            navigateToStep({ step: EnumNewTransactionParams.Kyc })
          } else if (protectionEnabled) {
            navigateToStep({ step: EnumNewTransactionParams.PaymentProtectionDisclosure })
          } else {
            navigateToStep({ step: EnumNewTransactionParams.UserInformation })
          }
        } else {
          navigateToStep({ step: EnumNewTransactionParams.SpecificVehicleInfo })
        }
      } else {
        console.log(globalStore.state.createTransaction.vehicle.registrationState)
        if (globalStore.state.createTransaction.vehicle.registrationState !== 'Vermont') {
          if (userType === EnumUserType.Main && shareStep === EnumShareStep.NeedsPayment && userRole === EnumUserRole.Buyer) {
            navigateToStep({ step: EnumNewTransactionParams.KnownIssuesAgreement })
          } else {
            navigateToStep({ step: EnumNewTransactionParams.Signature })
          }
        } else {
          navigateToStep({ step: EnumNewTransactionParams.FeeInfo })
        }
      }
      break

    case EnumNewTransactionParams.OrderConfirm:
      globalStore.dispatch({
        type: 'RESET_TRANSACTION_STATE'
      })
      navigate(EnumRoute.NewTransaction)
      break

    case EnumNewTransactionParams.Kyc:
      if (userType === EnumUserType.Secondary && paymentTransferEnabled && protectionEnabled) {
        navigateToStep({ step: EnumNewTransactionParams.PaymentProtectionDisclosure })
      } else {
        navigateToStep({ step: EnumNewTransactionParams.UserInformation })
      }
      break

    default:
      navigate(EnumRoute.Home)
      break
    }
  }

  return {
    step,
    userType,
    userRole,
    isSeller,
    isBuyer,
    isSecondary,
    seller,
    buyer,
    shareTransactionId,
    hasBeenShared,
    shareStep,
    shareUrl,
    isFirstSharedPage,
    navigateToStep,
    goPrevStep
  }
}
