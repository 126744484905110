// WidgetFrame.tsx
import React, { ReactNode } from 'react'
import { Button } from 'antd'

import './WidgetFrame.scss'

interface WidgetFrameProps {
  title: string;
  description: string;
  icon?: ReactNode;
  image?: string;
  // SvgIcon: React.FC<React.SVGProps<SVGSVGElement>>;  // SVG component as a prop
  onClick: () => void;
  descriptionStyle?: React.CSSProperties;
}

const WidgetFrame: React.FC<WidgetFrameProps> = ({
  title, description, icon, image, descriptionStyle, onClick
}) => {
  const [isHovered, setIsHovered] = React.useState(false)

  return (
    <div className="widget-frame" onClick={onClick}>
      <div className="background">
        <b className="widget-title">{title}</b>
        <div className={`widget-details ${!description ? 'no-description' : ''}`}>
          {/* <div className="wrapper-parent">  */}
          <div className="description-wrapper">
            <div className="description-text" style={descriptionStyle}>{description || '\u00A0'}</div>
          </div>
          {/* Conditional rendering of icon or image */}
          {icon ? (
            <div className="icon" style={{ color: isHovered ? 'black' : 'initial' }}>{icon}</div>
          ) : (
            image && <img className="icon" loading="lazy" alt={title} src={image} />
          )}
          {/* <img className="icon" loading="lazy" alt={title} src={image} /> */}
        </div>
      </div>
    </div>
  )
}

export default WidgetFrame
