declare global {
  interface Window {
    gtag: any;
  }
}

export const sendPageView = (url: any) => {
  window.gtag('event', 'page_view', {
    page_path: url
  })
}
