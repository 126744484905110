import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import {
  Col, Form, Row, Slider, Space,
  Spin, Typography
} from 'antd'
import {
  GenerativeModel, getGenerativeModel, getVertexAI, Schema
} from 'firebase/vertexai'
import { set } from 'lodash-es'

import { Button } from 'src/components/Button'
import { FeatureCardsArray } from 'src/components/FeatureCard/FeatureCard'
import { Input, TextArea } from 'src/components/Input'
import { PageStepper } from 'src/components/PageStepper'
import { Spacer } from 'src/components/Spacer'
import { firebaseApp } from 'src/firebase/firebase'
import { EnumRoute } from 'src/router/types'
import { featureObj } from 'src/types/descriptionBuilder'
import { YEAR_REGEX } from 'src/utils/constants'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { decodeVinWithRetry } from 'src/utils/vinHelpers'

import './styles.scss'

const { Title, Text } = Typography

export default function DescriptionBuilder() {
  const [isLoading, setIsLoading] = useState(false)
  const [vin, setVin] = useState('')
  const [year, setYear] = useState('')
  const [make, setMake] = useState('')
  const [model, setModel] = useState('')
  const [trim, setTrim] = useState('')
  const [odometer, setOdometer] = useState('')
  const [mechanicalCondition, setMechanicalCondition] = useState(8)
  const [exteriorCondition, setExteriorCondition] = useState(8)
  const [interiorCondition, setInteriorCondition] = useState(8)
  const [error, setError] = useState('')
  const [vinVehicleInfo, setVinVehicleInfo] = useState({})

  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [initialFeatures, setInitialFeatures] = useState<featureObj>({})
  const [limitedProdFeatures, setLimitedProdFeatures] = useState<{ [key: string]: any; }>({})
  const [gpt3Features, setGpt3Features] = useState<JSON | null>(null)
  const [vinProcessed, setVinProcessed] = useState(false)
  const [mainModel, setMainModel] = useState<GenerativeModel | null>(null)
  const [trimModel, setTrimModel] = useState<GenerativeModel | null>(null)
  const [getAllTrims, setGetAllTrims] = useState<GenerativeModel | null>(null)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const vid = queryParams.get('vid')
  const section = queryParams.get('section') // Get the section parameter

  const [currentPage, setCurrentPage] = useState(0)
  const [completedPages, setCompletedPages] = useState<number[]>([]) // Track completed pages
  const totalPages = 4

  useEffect(() => {
    console.log('completed pages', completedPages)
    console.log('vid', vid)
  }, [completedPages])

  useEffect(() => {
    const { hash } = window.location

    if (hash.includes('#page1') && vid) {
      // Set the current page to 0
      handlePullSuccess() // Mark page 1 as completed and go to page 2
      setCurrentPage(0)
    }
    // Check if the hash contains 'page2'
    if (hash.includes('#page2') && vid) {
      // Set the current page to 1 (representing page 2)
      setCurrentPage(1)
      handlePullSuccess() // set page 1 as completed
    }
  }, [vid])

  const handlePageClick = (page: number) => {
    console.log('click')
    const MAX_PAGE = Math.max(...completedPages, currentPage)
    const ERRORS = {
      UNCOMPLETED_PAGE: 'Please complete the current page before moving forward.',
      VIN_REQUIRED: 'Please pull vehicle information using the VIN or enter manually before continuing.',
      PAGE_ONE_INCOMPLETE: 'Please complete page 1 before continuing.'
    }
    console.log('Max page', MAX_PAGE)
    console.log('Page', page)
    console.log('completed pages', completedPages)
    console.log('vinProcessed', vinProcessed)
    console.log('vin', vid)
    if (page > MAX_PAGE) {
      if (page === 2 && vinProcessed && vid) {
        continueToConfirmation()
        return
      }
      console.log('here')
      setError(ERRORS.UNCOMPLETED_PAGE)
      return
    }
    if (page === 1 && !vinProcessed) {
      setError(ERRORS.VIN_REQUIRED)
      return
    }
    if (page === 2 && !completedPages.includes(1) && !vinProcessed) {
      console.log('hereeee')
      setError(ERRORS.PAGE_ONE_INCOMPLETE)
      return
    }
    setCurrentPage(page)
    setError('')
    updateUrlHash(page)
  }

  const updateUrlHash = (page: number) => {
    const currentUrl = new URL(window.location.href)
    const vid = currentUrl.searchParams.get('vid') // Retrieve the 'vid' from the URL if available

    if (vid) {
      currentUrl.hash = `#/description-builder?vid=${vid}#page${page + 1}`
    } else {
      currentUrl.hash = `#/description-builder#page${page + 1}`
    }

    window.history.pushState({}, '', currentUrl.toString())
  }
  const completePage = (page: number) => {
    if (!completedPages.includes(page)) {
      setCompletedPages((prev) => [...prev, page])
    }
  }

  // Call this function when pullByVIN or pullManually is completed successfully
  const handlePullSuccess = () => {
    setVinProcessed(true)
    completePage(1) // Mark page 1 as completed
    setCurrentPage(1) // Move to page 2
  }

  // Call this function when continueToConfirmation is completed successfully
  const handleConfirmationSuccess = () => {
    completePage(2) // Mark page 2 as completed
    setCurrentPage(2) // Move to page 3
  }

  // fetch car data if vid is present in the query params
  useEffect(() => {
    const fetchData = async () => {
      if (vid) {
        try {
          const api = await authApiAxiosWithRefresh()
          const response: any = await api.get(`/ai-description/get/car/${vid}`)

          const allData = response.data.carData
          const features: featureObj = allData.carFeatures
          const { carInfo } = allData
          // setCondition(carInfo.condition)
          console.log('carinfo', carInfo)
          setExteriorCondition(carInfo.exteriorCondition)
          setInteriorCondition(carInfo.interiorCondition)
          setMechanicalCondition(carInfo.mechanicalCondition)
          setOdometer(carInfo.odometer)
          setInitialFeatures(features)
          form.setFieldsValue(carInfo)
          // also set condition as the number conversion
        } catch (error) {
          console.error('Error parsing data:', error)
        }
      }
    }

    fetchData()
  }, [vid])

  const jsonSchema = Schema.object({
    properties: {
      'Limited Production Features': Schema.array({
        items: Schema.object({
          properties: {
            'Name and Production Limit': Schema.string(),
            'Special Features': Schema.object({
              properties: {
                'Engine and Transmission Features': Schema.array({ items: Schema.string() }),
                'Brake and Suspension Features': Schema.array({ items: Schema.string() }),
                'Exterior Features': Schema.array({ items: Schema.string() }),
                'Interior Features': Schema.array({ items: Schema.string() }),
                'Additional Features': Schema.array({ items: Schema.string() }),
                'Exterior Color': Schema.array({ items: Schema.string() })
              }
            })
          }
        })
      }),
      'Trim Packages': Schema.array({
        items: Schema.object({
          properties: {
            'Name and Production Limit': Schema.string(),
            'Special Features': Schema.object({
              properties: {
                'Engine and Transmission Features': Schema.array({ items: Schema.string() }),
                'Brake and Suspension Features': Schema.array({ items: Schema.string() }),
                'Exterior Features': Schema.array({ items: Schema.string() }),
                'Interior Features': Schema.array({ items: Schema.string() }),
                'Additional Features': Schema.array({ items: Schema.string() }),
                'Exterior Color': Schema.array({ items: Schema.string() })
              }
            })
          }
        })
      }),
      'Engine and Transmission Features': Schema.array({ items: Schema.string() }),
      'Brake and Suspension Features': Schema.array({ items: Schema.string() }),
      'Exterior Features': Schema.array({ items: Schema.string() }),
      'Interior Features': Schema.array({ items: Schema.string() }),
      'Additional Features': Schema.array({ items: Schema.string() }),
      'Exterior Color': Schema.array({ items: Schema.string() })
    },
    optionalProperties: ['Limited Production Features', 'Trim Packages', 'Exterior Color']
  })

  const initializeMainModel = async () => {
    const vertexAI = await getVertexAI(firebaseApp)

    const model = await getGenerativeModel(vertexAI, {
      model: 'gemini-1.5-pro',
      generationConfig: {
        maxOutputTokens: 3000,
        temperature: 0,
        responseMimeType: 'application/json',
        responseSchema: jsonSchema,
        topP: 0,
        topK: 1
      },
      systemInstruction: {
        role: 'system',
        parts: [
          {
            text: `
            You are tasked with creating a list of all interesting features for a specific car make and model. 
            The user will provide the car make, model, year, and all trims and limited production features to include in your response. 
            You must research the car and provide all interesting factory features the car may have. 
            For those features that come standard to the specific  vehicle, add the text "[Standard]" 
            to the begining of the specific feature, for the features that are factory optional, add the text "[Optional]".
            For those features that are rare or saught after, add the text "[Rare]".
            Follow the Json schema provided for formatting your response. 
            You should categorized car features into the categories provided, 
            making sure features are in their most apropriate category,
            and that no feature is repeated in multiple categories. 
            Here are some examples of features with appropriate amount of description:
            [Harman Kardon 18-speaker audio system, Bilstein adaptive damping suspension, 
            TorqueFlite eight-speed automatic transmission, 3.8-liter twin-turbo V6 engine producing 545 horsepower]. 
            Note these would all be individual features in the JSON.



                  In addition, research all standard features for every trim model provided by the user. 
                  Include every feature that comes standard for the trim under Special Features. 
                  Make sure these are categorized correctly. 
                  Don't include optional features for the trims here. 
                  Make sure to include all trims provided by the user and the standard features for those trims.

        

                  In addition, research all standard features for every limited production feature provided by the user. 
                  Include every feature that comes standard for the trim under Special Features. 
                  Make sure these are categorized correctly. 
                  Don't include optional features for the trims here. 
                  Make sure to include all trims provided by the user and the standard features for those trims.

        

                  In the rest of the exterior JSON (highest nesting level),
                   include factory optional features for the vehicle that are important to the vehicle. 
                   Avoid adding era standard features that would not be interesting to a buyer, 
                   such as ABS or traction conrol on modern cars. 
                   Also any other notable features missed when describing the trims and limited features.  



                  Additional features should be reserved for features that are not actually pieces of the car such as:
                   car covers, accessories, or key features.



                  Include Factory color options under Factory Color Options with the color code if available. 
                  If color options are considered rare, add the text "[Rare]" to the color option.

        

                  In general, make sure each feature is a detailed, specific, and unbiased description 
                  of a factory-designed feature on the car with part manufacturer if available. 
                  Make sure to describe all features that might be famous on a vehicle, especially for historical vehicles. 
                  Make sure to include only features available on this year of car. 
                  Speed or safety ratings are not considered features, 
                  also don't include features that come standard for all cars of this era
                   (ie power windows, air conditioning, anti lock braking, traction control on modern cars). 
                   Features should be positive, and are intended to sell a vehicle.

        

                  Your response should be only the JSON I've described, don't add any other text.
                  `
          }
        ]
      }
    })

    const getAllTrims = await getGenerativeModel(vertexAI, {
      model: 'gemini-1.5-pro',
      generationConfig: {
        maxOutputTokens: 1000,
        temperature: 0,
        topP: 0,
        topK: 1
      },
      systemInstruction: {
        role: 'system',
        parts: [
          {
            text: `
              Return a list of all factory trims and limited production models for a provided car make/model/year. 
              Make sure to include every single trim and limited production model for the make/model/year your response.
              Double check to make sure the information is accurate and up to date. 
              Make sure trims and limited production models were on the model and year of car provided.
              For limited production features, include the production limit in the string. 
              Limited production models may include special editions of the car. 
              Your response should be a text list of the trim names and a second text list for limited production models. 
              Trims should follow this format: model name trim name. 
              Limited prod features should foolow this format: 
              model name trim name [production limited to x units].
              `
          }
        ]
      }
    })
    setMainModel(model)
    setGetAllTrims(getAllTrims)
  }

  const initializeTrimModel = async () => {
    const vertexAI = await getVertexAI(firebaseApp)
    const trimModel = await getGenerativeModel(vertexAI, {
      model: 'gemini-1.5-pro',
      generationConfig: {
        maxOutputTokens: 3000,
        temperature: 0,
        responseMimeType: 'application/json',
        responseSchema: jsonSchema,
        topP: 0,
        topK: 1
      },
      systemInstruction: {
        role: 'system',
        parts: [
          {
            text: `
            You are tasked with creating a list of factory features for a specific car make, model and trim. 
            The user will provide the car details and you must find all factory features that are unique and or important to the car. 
            For those features that come standard to the specific  vehicle, 
            add the text "[Standard]" to the begining of the specific feature, 
            for the features that are factory optional, add the text "[Optional]". 
            For those features that are rare or saught after, add the text "[Rare]". 
            Follow the Json schema provided for formatting your response. 
            You should categorized car features into the categories provided, 
            making sure features are in their most apropriate category and that no feature is repeated in multiple categories. 
            Here are some examples of features with appropriate amount of description:
             [Harman Kardon 18-speaker audio system, 
              Bilstein adaptive damping suspension, 
              TorqueFlite eight-speed automatic transmission, 
              [Standard] 3.8-liter twin-turbo V6 engine producing 545 horsepower].

            Research the trim package provided by the user and categorize 
            it in either the Trim packages or the limited production features category. 
            Trim packages refers to all standard non limited production trims for the car. 
            Limited production features refers to special edition or rare trims that are hard to find.

            The Trim Packages JSON should follow the format in the Json Schema. 
            Include every important feature for the trim under Special Features. 
            Make sure these special features are correctly categorized and are actually present on the trim package.
      
            Place limited production versions of the car under "Limited Production Features". 
            Consider these rare or hard-to-find trim versions. 
            If you can find a production limit, make sure to include that in the title of the feature, 
            which is the string Name and Production Limit. 
            Include every feature for the limited production feature under Special Features.
            Make sure these special features are correctly categorized and are actually present on the limited package. 

            In the rest of the exterior JSON (highest nesting level),
             include factory optional features for the vehicle that are important to the vehicle. 
             Avoid adding era standard features that would not be interesting to a buyer, 
             such as ABS or traction conrol on modern cars. 
             Also any other notable features missed when describing the trims and limited features. 
             Always check to make sure a feature isn't repeated in multiple categories.

            Additional features should be reserved for features that are not actually pieces of the car such as:
            car covers, accessories, or key features.

            Include Factory color options under Factory Color Options with the color code if available. 
            If color options are considered rare, add the text "[Rare]" to the color option.

            In general, make sure each feature is a detailed, specific, 
            and unbiased description of a factory-designed feature on the car with part manufacturer if available. 
            Make sure to describe all features that might be famous on a vehicle, 
            especially for historical vehicles. 
            Make sure to include only features available on this year of car. 
            Speed or safety ratings are not considered features, 
            also don't include features that come standard for all cars of this era 
            (ie power windows, air conditioning, anti lock braking, traction control, cruise control on modern cars).
            Features should be positive, and are intended to sell a vehicle.
      
            Your response should be only the JSON I've described, don't add any other text.`
          }
        ]
      }
    })
    setTrimModel(trimModel)
  }
  // for now, initialize the models on page load
  useEffect(() => {
    initializeMainModel()
    initializeTrimModel()
  }, [])

  // pull the vin data and pass to the gpt model
  const pullByVIN = async (vin: any, year: any) => {
    try {
      const trimmedVin = vin.trim()
      const trimmedYear = year.trim()
      const vinVehicleInfo = await decodeVinWithRetry(trimmedVin, trimmedYear)

      setVinProcessed(true)
      handlePullSuccess() // Mark page 1 as completed and go to page 2
      setCurrentPage(1) // Set to page 1 to switch view
      if (vinVehicleInfo) {
        form.setFieldsValue({
          make: vinVehicleInfo.Make ?? form.getFieldValue('make') ?? '',
          model: vinVehicleInfo.Model ?? form.getFieldValue('model') ?? '',
          year: vinVehicleInfo.ModelYear ?? form.getFieldValue('year') ?? '',
          trim: vinVehicleInfo.Trim ?? form.getFieldValue('trim') ?? ''
        })
        const filteredVinVehicleInfo = Object.fromEntries(
          Object.entries(vinVehicleInfo).filter(([_, value]) => value !== '')
        )
        setVinVehicleInfo(filteredVinVehicleInfo)
        getGpt3Features()
      }
    } catch (error) {
      setError('Error getting data from model, please retry or enter manually')
    }
  }

  // pass entered data directly to gpt model
  const pullManually = async () => {
    try {
      await getGpt3Features() // Assuming this function fetches and sets initial features

      // Move to page 2 if data retrieval is successful
      setVinProcessed(true)
      handlePullSuccess() // Mark page 1 as completed and go to page 2
      setCurrentPage(1)
    } catch (error) {
      setError('Error getting data from model, please retry or enter manually')
    }
  }

  // get features from vertex
  async function getGpt3Features() {
    setIsLoading(true)
    try {
      const formData = form.getFieldsValue()

      const hasValues = Object.values(formData).some((value) => value !== undefined && value !== null && value !== '')

      if (!hasValues) {
        setError('Form data is empty. Please fill out the form before submitting.')
        throw new Error('Form data is empty. Please fill out the form before submitting.')
      } else {
        setError('')
      }

      const prompt = JSON.stringify(formData, null, 2)
      let result
      // initialize and run trim model
      if (formData.trim !== '') {
        // Ensure trimModel is initialized
        if (trimModel) {
          console.log('trim model')
          result = await trimModel.generateContent(prompt)
        } else {
          console.error('Failed to initialize trimModel')
          setError('Model not initialized. Try again')
        }
      } else {
      // initialize and run get all trims and main model
        if (mainModel && getAllTrims) {
          console.log('main model')
          const alltrims = await getAllTrims.generateContent(prompt)
          console.log(alltrims.response.text())
          result = await mainModel.generateContent(prompt + alltrims.response.text())
        } else {
          // Handle the case where model is still null after initialization
          console.error('Failed to initialize model')
          setError('Model not initialized. Try again')
        }
      }

      if (result) {
        setGpt3Features(JSON.parse(result.response.text()))
      }
    } catch (error: any) {
      // Log the entire error object for more details

      console.error('Error fetching model data:', error)

      // Check if the error is related to JSON parsing
      if (error instanceof SyntaxError) {
        console.error('JSON parsing error:', error.message)
        setError('Error parsing response data, please try again')
      } else if (error.response) {
        // Log the response data if available
        console.error('API response error:', error.response.data)
        setError(`API error: ${error.response.data.message || 'Unknown error'}`)
      } else {
        setError('Error Fetching model data, please try again')
      }
    } finally {
      setIsLoading(false)
    }
  }

  // set initial features state, initial features follows format category: [feature1, feature2, ...]
  // also set limitedProdFeatures state, limitedProdFeatures follows format nameAndLimit: specialFeatures object
  useEffect(() => {
    if (!gpt3Features) return

    const features: { [category: string]: { text: string; initialStatus: 'pending' }[] } = {}
    const limitedFeatures: { [key: string]: any; } = {}

    Object.entries(gpt3Features).forEach(([category, categoryFeatures]) => {
      if (categoryFeatures.length === 0) return // Skip if no features for this category

      if (category === 'Limited Production Features' || category === 'Trim Packages') {
        features[category] = categoryFeatures.map((feature: any) => {
          const nameAndLimit = feature['Name and Production Limit']
          limitedFeatures[nameAndLimit] = feature['Special Features']
          return { text: nameAndLimit, initialStatus: 'pending' }
        })
      } else {
        features[category] = categoryFeatures.map((feature: any) => ({ text: feature, initialStatus: 'pending' }))
      }
    })

    setInitialFeatures({ ...features }) // Ensure new object reference
    setLimitedProdFeatures({ ...limitedFeatures }) // Ensure new object reference
  }, [gpt3Features])

  const handleStatusChange = (newStatus: 'added' | 'pending', category: string, feature: string | {key: string, value: any}) => {
    if (category === 'Limited Production Features' || category === 'Trim Packages') {
      const specialFeatures = limitedProdFeatures[feature as string]
      if (specialFeatures) {
        // add limited production features to initial features only the first time, if its already there update the status to newStatus
        setInitialFeatures((prevFeatures) => {
          const newFeatures = { ...prevFeatures }
          Object.entries(specialFeatures).forEach(([category, features]) => {
            const tempStatus = (category === 'Exterior Color') ? 'pending' : newStatus
            const featureArray = features as string[]
            if (newFeatures[category]) {
              featureArray.forEach((feature: any) => {
                const featureToAdd: { text: any; initialStatus: 'pending' | 'added'; } = { text: feature, initialStatus: tempStatus }
                // add feature if not already present
                if (!newFeatures[category].some((f: any) => f.text === featureToAdd.text)) {
                  newFeatures[category].unshift(featureToAdd)
                } else {
                  // update status if feature already present
                  newFeatures[category] = newFeatures[category].map((f) => (
                    f.text === featureToAdd.text
                      ? { ...f, initialStatus: tempStatus }
                      : f))
                }
              })
            } else {
              newFeatures[category] = featureArray.map((feature: any) => ({ text: feature, initialStatus: tempStatus }))
            }
          })
          return newFeatures
        })
      }
    }
    // update initial status to avoid re-rendering with old status
    setInitialFeatures((prevFeatures) => {
      const newFeatures = { ...prevFeatures }
      const featureText = typeof feature === 'string' ? feature : feature.key
      const featureIndex = newFeatures[category].findIndex((f) => f.text === featureText)

      if (featureIndex !== -1) {
        newFeatures[category][featureIndex].initialStatus = newStatus
      }
      return newFeatures
    })
  }

  const handleTextChange = (newText: string, category: string, prevText: string) => {
    setInitialFeatures((prevFeatures) => {
      const newFeatures = { ...prevFeatures }
      const featureIndex = newFeatures[category].findIndex((f) => f.text === prevText)
      if (featureIndex !== -1) {
        newFeatures[category][featureIndex].text = newText
      } else if (newText.length > 0) {
        // Add new feature if it doesn't exist
        newFeatures[category].push({
          text: newText,
          initialStatus: 'added'
        })
      }
      return newFeatures
    })
  }

  function cleanInitialFeatures(initFeatures: { [key: string]: { text: string, initialStatus: string }[] }) {
    const cleanedFeatures: { [key: string]: { text: string, initialStatus: string }[] } = {}

    Object.entries(initFeatures).forEach(([category, features]) => {
      if (features.length !== 0) {
        cleanedFeatures[category] = features.filter((feature) => feature.initialStatus === 'added')
      }
    })

    return cleanedFeatures
  }

  const continueToConfirmation = async () => {
    if (initialFeatures) {
      const formInfo = form.getFieldsValue()
      const { make, model, year } = formInfo

      // Validation check
      if (!make || !model || !year) {
        setError('Please ensure Make, Model, and Year are filled out before continuing.')
        return
      }

      // Prepare car data for API call
      const carData = {
        carInfo: formInfo,
        carFeatures: initialFeatures
      }

      // Post the saved car info
      const api = await authApiAxiosWithRefresh()
      const response: any = await api.post('/ai-description/save/car', { vid, carData })

      const newVid = response.data.vid

      // Construct the URL for confirmation page
      const currentUrl = new URL(window.location.href)
      currentUrl.hash = `#/description-builder?vid=${newVid}`
      const newUrl = currentUrl.toString()
      window.history.pushState({ path: newUrl }, '', newUrl)

      handleConfirmationSuccess() // Mark page 2 as completed and go to page 3

      // Trigger the swipe transition by updating currentPage
      setCurrentPage(2)

      // Delay navigation to allow the swipe effect to complete
      setTimeout(() => {
        navigate(`${EnumRoute.DescriptionConfirmation}?vid=${newVid}`)
      }, 500) // Delay (in ms) should match the CSS transition duration
    } else {
      console.error('Could not pass data to confirmation')
    }
  }

  const [isTrimChanged, setIsTrimChanged] = useState(false)

  // Handler for Trim change
  const handleTrimChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTrim = e.target.value
    setTrim(newTrim)
    setIsTrimChanged(true) // Set to true whenever Trim is changed
  }

  const yearCustomValidationRule = () => ({
    validator(_: any, value: any) {
      if (
        YEAR_REGEX.test(value)
        && value <= new Date().getUTCFullYear()
        && value > 1886
      ) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('Year is required'))
    }
  })

  const vinCustomValidationRule = () => ({
    validator(_: any, value: any) {
      if (value && value.length >= 17) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('VIN must be 17 characters long'))
    }
  })

  const userDefFields = () => {
    return (
      <>
        <Spacer space={5} />
        <Form.Item name="modificationsAndMaintenance">
          <h2>Modifications and Maintenance</h2>
          <TextArea
            placeholder="Describe anything else you want to mention in the ad including any modifications, maintenance, or unique features"
            rows={5}
            onChange={(e) => {
              form.setFieldsValue({ modificationsAndMaintenance: e.target.value })
            }}
          />
        </Form.Item>
        <Form.Item name="odometer">
          <h2>Odometer Reading</h2>
          <Input
            value={odometer}
            placeholder="Odometer Reading (mi)"
            onChange={(e) => {
              setOdometer(e.target.value)
              form.setFieldsValue({ odometer: e.target.value })
            }}
          />
        </Form.Item>

        <h2>Vehicle Condition</h2>
        <Form.Item name="exteriorCondition">
          <Text className="specific-vehicle-information-page__form__vin-description">
            Add a vehicle exterior condition rating from 0 to 10. 0 being a scrap car and 10 being perfect condition.
          </Text>
          <Spacer space={5} />
          <div className="slider-wrapper">
            <span>0</span>
            <Slider
              value={exteriorCondition}
              min={0}
              max={10}
              tooltip={{ open: true, placement: 'bottom' }}
              onChange={(e) => {
                setExteriorCondition(e)
                form.setFieldsValue({ exteriorCondition: e })
              }}
            />
            <span>10</span>
          </div>
          <Spacer space={30} />
        </Form.Item>
        {exteriorCondition <= 7 && (
          <Form.Item name="exteriorIssues">
            <TextArea
              placeholder="Describe exterior issues ie rusting, fading, worn parts, etc"
              rows={5}
              onChange={(e) => {
                form.setFieldsValue({ exteriorIssues: e.target.value })
              }}
            />
            <Spacer space={20} />
          </Form.Item>
        )}

        <Form.Item name="interiorCondition">
          <Text className="specific-vehicle-information-page__form__vin-description">
            Add a vehicle interior condition rating from 0 to 10.
          </Text>
          <Spacer space={5} />
          <div className="slider-wrapper">
            <span>0</span>
            <Slider
              value={interiorCondition}
              min={0}
              max={10}
              tooltip={{ open: true, placement: 'bottom' }}
              onChange={(e) => {
                setInteriorCondition(e)
                form.setFieldsValue({ interiorCondition: e })
              }}
            />
            <span>10</span>
          </div>
          <Spacer space={30} />
        </Form.Item>

        {interiorCondition <= 7 && (
          <Form.Item name="interiorIssues">
            <TextArea
              placeholder="Describe interior issues ie upholstery damage, etc"
              rows={5}
              onChange={(e) => { form.setFieldsValue({ interiorIssues: e.target.value }) }}
            />
            <Spacer space={20} />
          </Form.Item>
        )}

        <Form.Item name="mechanicalCondition">
          <Text className="specific-vehicle-information-page__form__vin-description">
            Add a mechanical condition rating from 0 to 10.
          </Text>
          <Spacer space={5} />
          <div className="slider-wrapper">
            <span>0</span>
            <Slider
              value={mechanicalCondition}
              min={0}
              max={10}
              tooltip={{ open: true, placement: 'bottom' }}
              onChange={(e) => {
                setMechanicalCondition(e)
                form.setFieldsValue({ mechanicalCondition: e })
              }}
            />
            <span>10</span>
          </div>
          <Spacer space={30} />
        </Form.Item>
        {mechanicalCondition <= 7 && (
          <Form.Item name="mechanicalIssues">
            <TextArea
              placeholder="Describe mechanical issues ie engine problems, leaking, etc"
              rows={5}
              onChange={(e) => {
                form.setFieldsValue({ mechanicalIssues: e.target.value })
              }}
            />
          </Form.Item>
        )}

      </>
    )
  }

  return (

    <Spin className="spinner" spinning={isLoading} size="large">
      <Row className="specific-vehicle-information-page">

        <Col span={24}>
          <PageStepper currentPage={currentPage} totalPages={totalPages} setCurrentPage={handlePageClick} />
        </Col>
        <Form
          form={form}
          className="kuaay-form specific-vehicle-information-page__form"
          initialValues={{
            make: '',
            model: '',
            color: '',
            fuelTypePrimary: '',
            bodyClass: '',
            displacementL: '',
            numCylinders: '',
            year: '',
            doors: '',
            trim: '',
            exteriorCondition,
            interiorCondition,
            mechanicalCondition,
            odometer
          }}
        >
          {/* Page 1 - Form Content */}
          <Col
            id="page1"
            className={`page-content ${currentPage === 0 ? 'swipe-in' : 'swipe-out'}`}
            style={{ display: currentPage === 0 ? 'block' : 'none' }}
          >

            <Col className="flex-col" span={20} offset={2}>
              <Title className="specific-vehicle-information-page__title" level={4}>
                AI Listing Description Builder
              </Title>
              <Spacer space={24} />

              <Text className="specific-vehicle-information-page__form__vin-description">
                Enter the Vehicle Identification Number (US VINs only) to automatically pull unique features about the vehicle.
                Add custom information below to specify what details are important for your customers!
              </Text>
              <Spacer space={24} />

              <Row gutter={16} className="description-builder" align="middle">
                {/* Left Side - VIN Input and Button */}
                <Col xs={24} md={11} className="left-section" style={{ position: 'relative' }}>
                  <Form.Item name="vin" initialValue={vin} rules={[vinCustomValidationRule()]}>
                    <Input placeholder="VIN/HIN" onChange={(e) => setVin(e.target.value)} />
                  </Form.Item>

                  {/* Button with Absolute Positioning */}
                  <Button
                    type="primary"
                    color="primary"
                    onClick={() => pullByVIN(vin, year)}
                    disabled={!vin || vin.length < 17 || isLoading} // Disabled unless VIN is 17 chars
                    className="pull-vin-btn"
                  >
                    Pull Info using VIN
                  </Button>
                </Col>

                {/* OR Text in Center */}
                <Col xs={24} md={2} className="or-text" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Text className="text">OR</Text>
                </Col>

                {/* Right Side - Manual Input */}
                <Col xs={24} md={11} className="right-section">
                  <Form.Item name="year" rules={[yearCustomValidationRule()]}>
                    <Input placeholder="Year" onChange={(e) => setYear(e.target.value)} />
                  </Form.Item>
                  <Form.Item name="make" rules={[{ required: true, message: 'Make is required' }]}>
                    <Input placeholder="Make" onChange={(e) => form.setFieldsValue(setMake(e.target.value))} />
                  </Form.Item>
                  <Form.Item name="model" rules={[{ required: true, message: 'Model is required' }]}>
                    <Input placeholder="Model" onChange={(e) => form.setFieldsValue(setModel(e.target.value))} />
                  </Form.Item>
                  <Form.Item name="trim">
                    <Input placeholder="Trim (optional)" onChange={handleTrimChange} />
                  </Form.Item>
                  <Button
                    className="description-form__btn"
                    type="primary"
                    color="primary"
                    onClick={() => pullManually()}
                    disabled={(!make || !model || !year || isLoading) && !isTrimChanged}
                  >

                    Pull Info Manually
                  </Button>
                </Col>
              </Row>

              <Spacer space={10} />
              {error && <Text type="danger">{error}</Text>}

            </Col>

          </Col>

          {/* Page 2 - Feature Cards Content */}
          {!isLoading && (
            <Col
              id="page2"
              className={`page-content ${currentPage === 1 ? 'swipe-in' : 'swipe-out'}`}
              style={{ display: currentPage === 1 ? 'block' : 'none' }}
            >

              <Col className="flex-col" span={20} offset={2}>

                {Object.keys(initialFeatures).length > 0 && (
                  <Text className="specific-vehicle-information-page__form__vin-description">
                    Select suggested car features you want to mention in the ad.
                    You can edit, add, or remove options as needed.
                  </Text>
                )}
                <FeatureCardsArray
                  initialFeatures={initialFeatures}
                  handleStatusChange={handleStatusChange}
                  handleTextChange={handleTextChange}
                />
              </Col>
              <Col className="flex-col" span={20} offset={2}>
                {userDefFields()}
                <Button
                  className="description-form__btn"
                  type="primary"
                  color="primary"
                  onClick={continueToConfirmation}
                >
                  Continue
                </Button>
              </Col>
            </Col>
          )}
        </Form>

      </Row>
    </Spin>

  )
}
