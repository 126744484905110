import React, { useEffect, useMemo } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { MailOutlined } from '@ant-design/icons'
import {
  Col, Form, Row, Typography
} from 'antd'
import {
  EmailAuthProvider,
  linkWithCredential
} from 'firebase/auth'

import { Button } from 'src/components/Button'
import { Checkbox } from 'src/components/Checkbox'
import { HeaderLayout } from 'src/components/HeaderLayout'
import { Input, PasswordInput } from 'src/components/Input'
import LoadingModal from 'src/components/Modal/LoadingModal'
import { USStateSelect } from 'src/components/USStateSelect'
import { getFirebaseAuth } from 'src/firebase/firebase'
import { useAuthen } from 'src/hooks/useAuthen'
import { EnumRoute, EnumRouteSearchParam } from 'src/router/types'
import { store } from 'src/store/store'
import {
  ALLOW_ANONYMOUS_USERS, EMAIL_REGEX, PHONE_NUMBER_REGEX, ZIP_CODE_REGEX
} from 'src/utils/constants'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { formatPhoneNumber } from 'src/utils/phoneHelpers'

import './styles.scss'

interface AuthResponse {
  type: 'success' | 'failure';
  uid: string;
  // token: string; // DEPRECATED
}

const { Title, Text } = Typography

export default function SignUpPage() {
  const globalStore = React.useContext(store)
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const { isAuthenLoaded, currentUser, isAnonymous } = useAuthen()
  const [errMessage, setErrMessage] = React.useState<string>('')
  const [signingUpModalVisible, setSigningUpModalVisible] = React.useState(false)
  const [signUpAsOrg, setSignUpAsOrg] = React.useState(false)
  const isInitialized = useMemo(() => isAuthenLoaded && navigate, [isAuthenLoaded, navigate])
  const [form] = Form.useForm()

  // useEffect(() => {
  //   if (isInitialized && !isAnonymous) {
  //     navigate(EnumRoute.Account)
  //   }
  // }, [isInitialized, navigate, isAnonymous])

  const signUpWithEmail = async ({
    fullName, email, password, phone
  }: { fullName: string; email: string; password: string; phone: string; }) => {
    setErrMessage('')
    setSigningUpModalVisible(true)

    try {
      // Now sign them in!
      try {
        const firebaseAuth = getFirebaseAuth()
        if (firebaseAuth.currentUser) {
          // Get the email credential
          const emailCredential = EmailAuthProvider.credential(
            email,
            password
          )
          // Now link it with the current anonymous account (upgraaayyyydddd)
          const { user } = await linkWithCredential(firebaseAuth.currentUser, emailCredential)
          // Now tell the backend
          const loginResp = await (
            await authApiAxiosWithRefresh()
          ).post('/auth', {

            token: await firebaseAuth.currentUser?.getIdToken(true),
            fullName,
            phone,
            signUpAsOrg
          })

          // const loginResp: AxiosResponse<AuthResponse> = await apiAxios.post(
          //   '/auth',
          //   {
          //     token: await firebaseAuth.currentUser?.getIdToken(true),
          //     fullName,
          //     phone
          //   }
          // )

          // So now stop the loading screen and either fail or succeed
          setSigningUpModalVisible(false)

          // If we failed :(
          if (loginResp.data.type === 'failure') {
            // eslint-disable-next-line no-console
            console.log(
              'Failed to login using email. Login response:',
              loginResp
            )
            setErrMessage(
              'Email Login Error. Please try again.\nError code: 424242'
            )
            return
          }

          // Well we signed in and can store stuffs and all that jazz
          globalStore.dispatch({
            type: 'SIGN_IN',
            user,
            token: user.getIdToken(),
            refreshToken: user.refreshToken,
            isAnonymous: false
          })
          globalStore.dispatch({
            type: 'UPDATE_PHONE',
            phone
          })
          // Reset the entered fields on the page
          setErrMessage('')
          // Now get and set their push token
          // Now register for push notifications and update the backend
          // TODO lanh
          // registerAndStorePushNotificationsAsync().catch((error) => {
          //   console.error(
          //     'Failed to register for push notifications. Error:',
          //     error
          //   )
          // })
          // Now fly away!
          setTimeout(() => {
            if (searchParams.has(EnumRouteSearchParam.RedirectUrl) && searchParams.get(EnumRouteSearchParam.RedirectUrl)) {
              const redirectRoute = searchParams.get(EnumRouteSearchParam.RedirectUrl)!
              navigate(redirectRoute)
            } else {
              navigate(
                ALLOW_ANONYMOUS_USERS ? EnumRoute.Account : EnumRoute.Home
              )
            }
          }, 300)
        } else {
          // What to do if we have don't have a current Firebase user
          setErrMessage(
            'Sign up error. Please try again in a few minutes.\nError code: 42069'
          )
          setSigningUpModalVisible(false)
          // eslint-disable-next-line no-console
          console.error('NO FIREBASE USER FOUND!')
        }
      } catch (error: any) {
        const errorCode = error.code
        const errorMessage = error.message
        if (errorCode === 'auth/weak-password') {
          setErrMessage('Password is too weak!')
        } else if (errorCode === 'auth/invalid-email') {
          setErrMessage('Please check the entered email.')
        } else if (errorCode === 'auth/email-already-in-use') {
          setErrMessage('Email is not valid.')
        } else {
          // eslint-disable-next-line no-console
          console.log(
            'Other login error encountered. Error code:',
            error,
            errorCode
          )
          setErrMessage(errorMessage)
        }
      }

      setSigningUpModalVisible(false)
    } catch (error: any) {
      setErrMessage(error.toString())
      setSigningUpModalVisible(false)
      // eslint-disable-next-line no-console
      console.log('SIGN UP ERROR:', error, error.code)
    }
  }

  const signUpAsOrganization = async ({
    fullName, email, password, phone, dealershipName, din, address, address2, city, state, zip
  }:
    {
      fullName: string;
      email: string;
      password: string;
      phone: string;
      dealershipName: string;
      din: string;
      address: string;
      address2: string;
      city: string;
      state: string;
      zip: string;
    }) => {
    setErrMessage('')
    setSigningUpModalVisible(true)
    console.log('signUpAsOrganization')
    try {
      try {
        const firebaseAuth = getFirebaseAuth()
        if (firebaseAuth.currentUser) {
          // Get the email credential
          const emailCredential = EmailAuthProvider.credential(
            email,
            password
          )
          console.log('emailCredential', emailCredential)
          console.log('firebaseAuth.currentUser', firebaseAuth.currentUser)
          // Now link it with the current anonymous account (upgraaayyyydddd)
          const { user } = await linkWithCredential(firebaseAuth.currentUser, emailCredential)
          console.log('user', user)
          // Now tell the backend
          const loginResp = await (
            await authApiAxiosWithRefresh()
          ).post('/auth', {
            token: await firebaseAuth.currentUser?.getIdToken(true),
            fullName,
            phone,
            signUpAsOrg,
            dealershipName,
            din,
            address,
            address2,
            city,
            state,
            zip
          })

          // const loginResp: AxiosResponse<AuthResponse> = await apiAxios.post(
          //   '/auth',
          //   {
          //     token: await firebaseAuth.currentUser?.getIdToken(true),
          //     fullName,
          //     phone
          //   }
          // )

          // So now stop the loading screen and either fail or succeed
          setSigningUpModalVisible(false)

          // If we failed :(
          if (loginResp.data.type === 'failure') {
            // eslint-disable-next-line no-console
            console.log(
              'Failed to login using email. Login response:',
              loginResp
            )
            setErrMessage(
              'Email Login Error. Please try again.\nError code: 424242'
            )
            return
          }

          // Well we signed in and can store stuffs and all that jazz
          globalStore.dispatch({
            type: 'SIGN_IN',
            user,
            token: user.getIdToken(),
            refreshToken: user.refreshToken,
            isAnonymous: false
          })
          globalStore.dispatch({
            type: 'UPDATE_PHONE',
            phone
          })
          // Reset the entered fields on the page
          setErrMessage('')
          // Now get and set their push token
          // Now register for push notifications and update the backend
          // TODO lanh
          // registerAndStorePushNotificationsAsync().catch((error) => {
          //   console.error(
          //     'Failed to register for push notifications. Error:',
          //     error
          //   )
          // })
          // Now fly away!
          setTimeout(() => {
            if (searchParams.has(EnumRouteSearchParam.RedirectUrl) && searchParams.get(EnumRouteSearchParam.RedirectUrl)) {
              const redirectRoute = searchParams.get(EnumRouteSearchParam.RedirectUrl)!
              navigate(redirectRoute)
            } else {
              navigate(
                ALLOW_ANONYMOUS_USERS ? EnumRoute.Account : EnumRoute.Home
              )
            }
          }, 300)
        } else {
          // What to do if we have don't have a current Firebase user
          setErrMessage(
            'Sign up error. Please try again in a few minutes.\nError code: 42069'
          )
          setSigningUpModalVisible(false)
          // eslint-disable-next-line no-console
          console.error('NO FIREBASE USER FOUND!')
        }
      } catch (error: any) {
        const errorCode = error.code
        const errorMessage = error.message
        if (errorCode === 'auth/weak-password') {
          setErrMessage('Password is too weak!')
        } else if (errorCode === 'auth/invalid-email') {
          setErrMessage('Please check the entered email.')
        } else if (errorCode === 'auth/email-already-in-use') {
          setErrMessage('Email is not valid.')
        } else {
          // eslint-disable-next-line no-console
          console.log(
            'Other login error encountered. Error code:',
            error,
            errorCode
          )
          setErrMessage(errorMessage)
        }
      }

      setSigningUpModalVisible(false)
    } catch (error: any) {
      setErrMessage(error.toString())
      setSigningUpModalVisible(false)
      // eslint-disable-next-line no-console
      console.log('SIGN UP ERROR:', error, error.code)
    }
  }

  const onDealershipChange = (e: { target: { checked: boolean } }) => {
    setSignUpAsOrg(e.target.checked)
  }
  return (
    <HeaderLayout title="Sign Up" hasGoBack>
      <Row className="sign-up-page">
        <Col span={20} offset={2}>
          {
            !isAuthenLoaded ? (
              <LoadingModal title="Loading..." />
            ) : (
              <>
                {
                  signingUpModalVisible && (
                    <LoadingModal title="Signing up..." />
                  )
                }
                <Title className="sign-up-page__title" level={2}>Sign Up</Title>

                <Checkbox
                  onChange={onDealershipChange}
                >
                  Register as an Organization
                </Checkbox>
                {
                  !signUpAsOrg ? (

                    <Form
                      className="sign-up-form kuaay-form"
                      name="signin"
                      form={form}
                      initialValues={{
                        fullName: '', email: '', phone: '', password: '', repeatPassword: ''
                      }}
                      onFinish={signUpWithEmail}
                    >
                      <Form.Item
                        name="fullName"
                        rules={[{ required: true, message: 'Please input your Full Name.' }]}
                      >

                        <Input
                          className="kuaay-input-v2"
                          placeholder="Full Name"
                        />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        rules={[
                          { required: true, message: 'Please input your Email' },
                          { pattern: EMAIL_REGEX, message: 'Incorrect email format' }
                        ]}
                      >
                        <Input
                          className="kuaay-input-v2"
                          placeholder="Email"
                          autoCapitalize="none"
                          inputMode="email"
                        />
                      </Form.Item>
                      <Form.Item
                        name="phone"
                        rules={[
                          { required: true, message: 'Please input your Phone Number.' },
                          { pattern: PHONE_NUMBER_REGEX, message: 'Inccorect Phone Number' }
                        ]}
                      >

                        <Input
                          className="kuaay-input-v2"
                          placeholder="Phone Number"
                          autoCapitalize="none"
                          inputMode={'tel'}
                          onChange={(e) => {
                            const formattedPhoneNumber = formatPhoneNumber(e.target.value, true)
                            form.setFieldValue('phone', formattedPhoneNumber)
                            form.validateFields(['phone'])
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name="password"
                        rules={[
                          { required: true, message: 'Please input your Password.' },
                          () => ({
                            validator(_, value) {
                              if (!value || value.length >= 8) {
                                return Promise.resolve()
                              }
                              return Promise.reject(new Error('Password must be at least 8 characters.'))
                            }
                          })
                        ]}
                      >
                        <PasswordInput
                          className="kuaay-password-input-v2"
                          placeholder="Password"
                          autoCapitalize="none"
                        />
                      </Form.Item>
                      <Form.Item
                        name="repeatPassword"
                        rules={[
                          { required: true, message: 'Please repeat your Password.' },
                          { min: 8, message: 'Repeat Password must be at least 8 characters.' },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve()
                              }
                              return Promise.reject(new Error('Passwords must match.'))
                            }
                          })
                        ]}
                      >
                        <PasswordInput
                          className="kuaay-password-input-v2"
                          placeholder="Repeat Password"
                          autoCapitalize="none"
                        />
                      </Form.Item>
                      <Text
                        type="danger"
                      >
                        {errMessage}
                      </Text>
                      <Form.Item className="sign-up-button-wrapper">

                        <Button
                          className="sign-up-button"
                          icon={<MailOutlined />}
                          type="primary"
                          htmlType="submit"
                        >
                          Sign Up
                        </Button>
                      </Form.Item>
                    </Form>
                  ) : (

                  // Organization Sign Up

                    <Form
                      className="sign-up-form kuaay-form"
                      name="signin"
                      form={form}
                      initialValues={{
                        dealershipName: '',
                        din: '',
                        email: '',
                        fullName: '',
                        phone: '',
                        password: '',
                        repeatPassword: '',
                        address: '',
                        address2: '',
                        city: '',
                        state: '',
                        zip: ''

                      }}
                      onFinish={signUpAsOrganization}
                    >
                      <Form.Item
                        name="dealershipName"
                        rules={[{ required: true, message: 'Please input your Dealship Name.' }]}
                      >

                        <Input
                          className="kuaay-input-v2"
                          placeholder="Dealership Name"
                        />
                      </Form.Item>
                      <Form.Item
                        name="din"
                        rules={[{ required: true, message: 'Dealership Identification Number (DIN)' }]}
                      >
                        <Input
                          className="kuaay-input-v2"
                          placeholder="DIN"
                        />
                      </Form.Item>
                      <Form.Item
                        name="address"
                        rules={[{ required: true, message: 'Business Address is required' }]}
                      >
                        <Input
                          placeholder="Business Address"
                        />
                      </Form.Item>
                      <Form.Item
                        name="address2"
                      >
                        <Input
                          placeholder="Business Apartment, suite, unit, etc."
                        />
                      </Form.Item>
                      <Row>
                        <Col xs={8}>
                          <Form.Item
                            name="zip"
                            rules={[
                              { required: true, message: 'Zip Code is required' },
                              { pattern: ZIP_CODE_REGEX, message: 'Incorrect Zip Code' }
                            ]}
                          >
                            <Input
                              maxLength={5}
                              placeholder="Zip"
                              inputMode={'numeric'}
                            />
                          </Form.Item>
                        </Col>
                        <Col offset={1} xs={15}>
                          <Form.Item
                            name="city"
                            rules={[{ required: true, message: 'Business City is required' }]}
                          >
                            <Input
                              placeholder="Business City, State/Province"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item
                        name="state"
                        rules={[{ required: true, message: 'Business State is required' }]}
                      >
                        <USStateSelect
                          placeholder="Business State"
                        />
                      </Form.Item>
                      <Form.Item
                        name="fullName"
                        rules={[{ required: true, message: 'Please input your Full Name.' }]}
                      >

                        <Input
                          className="kuaay-input-v2"
                          placeholder="Full Name"
                        />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        rules={[
                          { required: true, message: 'Please input your Email' },
                          { pattern: EMAIL_REGEX, message: 'Incorrect email format' }
                        ]}
                      >
                        <Input
                          className="kuaay-input-v2"
                          placeholder="Email"
                          autoCapitalize="none"
                          inputMode="email"
                        />
                      </Form.Item>
                      <Form.Item
                        name="phone"
                        rules={[
                          { required: false, message: 'Please input your Phone Number.' },
                          { pattern: PHONE_NUMBER_REGEX, message: 'Inccorect Phone Number' }
                        ]}
                      >

                        <Input
                          className="kuaay-input-v2"
                          placeholder="Phone Number"
                          autoCapitalize="none"
                          inputMode={'tel'}
                          onChange={(e) => {
                            const formattedPhoneNumber = formatPhoneNumber(e.target.value, true)
                            form.setFieldValue('phone', formattedPhoneNumber)
                            form.validateFields(['phone'])
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name="password"
                        rules={[
                          { required: true, message: 'Please input your Password.' },
                          () => ({
                            validator(_, value) {
                              if (!value || value.length >= 8) {
                                return Promise.resolve()
                              }
                              return Promise.reject(new Error('Password must be at least 8 characters.'))
                            }
                          })
                        ]}
                      >
                        <PasswordInput
                          className="kuaay-password-input-v2"
                          placeholder="Password"
                          autoCapitalize="none"
                        />
                      </Form.Item>
                      <Form.Item
                        name="repeatPassword"
                        rules={[
                          { required: true, message: 'Please repeat your Password.' },
                          { min: 8, message: 'Repeat Password must be at least 8 characters.' },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve()
                              }
                              return Promise.reject(new Error('Passwords must match.'))
                            }
                          })
                        ]}
                      >
                        <PasswordInput
                          className="kuaay-password-input-v2"
                          placeholder="Repeat Password"
                          autoCapitalize="none"
                        />
                      </Form.Item>
                      <Text
                        type="danger"
                      >
                        {errMessage}
                      </Text>
                      <Form.Item className="sign-up-button-wrapper">

                        <Button
                          className="sign-up-button"
                          icon={<MailOutlined />}
                          type="primary"
                          htmlType="submit"
                        >
                          Sign Up
                        </Button>
                      </Form.Item>
                    </Form>
                  )
                }
                <Link
                  to={EnumRoute.SignIn + (searchParams.toString() ? `?${searchParams.toString()}` : '')}
                  className="sign-in-link"
                >
                  Already have an account?
                </Link>
              </>
            )
          }

        </Col>
      </Row>
    </HeaderLayout>
  )
}
