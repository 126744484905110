import { authApiAxiosWithRefresh } from 'src/utils/customAxios'

export const retrieveVerifPdf = async (orgName: string | null, dealerId: string | null, sessionId: string | null) => {
  try {
    const response = await (await authApiAxiosWithRefresh())
      .get(`stripe-identity/retrieve_pdf/${orgName}?dealerId=${dealerId}&sessionId=${sessionId}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf'
        }
      })

    const pdfBlob = new Blob([response.data], { type: 'application/pdf' })

    const blobURL = window.URL.createObjectURL(pdfBlob)
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', `Pacer_verification_${sessionId}.pdf`)
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(blobURL)
    }, 100)
  } catch (error) {
    console.log('Error retrieving pdf', error)
  }
}
